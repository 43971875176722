import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../utils/constants';

// const ImageSeeThroughwrapper = styled.div`
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: url(${(props) => props.imageUrl});
//   min-height: ${(props) => props.height};
//   background-attachment: fixed;
//   margin-top: 183px;
//   width: 100%;
//   @media screen and (max-width: ${MAX_WIDTH}) {
//     margin-top: 32px;
//   }
// `;

const ImageSeeThroughwrapper = styled.div`
  background-image: url(${(props) => props.imageUrl});
  margin-top: ${(props) => (props.isTop ? '0px' : '183px')};
  margin-bottom: ${(props) => (props.isTop ? '183px' : '0px')};
  width: 100vw;
  height: 260px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: ${MAX_WIDTH}) {
    margin-top: ${(props) => (props.isTop ? '0px' : '32px')};
    margin-bottom: ${(props) => (props.isTop ? '32px' : '0px')};
  }
`;

const ImageSeeThrough = ({ imageUrl, height, isTop }) => {
  return <ImageSeeThroughwrapper imageUrl={imageUrl} height={height} isTop={isTop}></ImageSeeThroughwrapper>;
};

ImageSeeThrough.propTypes = {
  imageUrl: PropTypes.string,
  height: PropTypes.string,
  isTop: PropTypes.bool,
};

export default ImageSeeThrough;
