import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { useMediaQuery } from 'react-responsive';
import { MAX_WIDTH } from '../../utils/constants';
import { topNavItems } from '../../utils/routes';
import logo_golden from '../../assets/logo_golden.svg';
import logo_white from '../../assets/logo_white.svg';
import navbar_platform from '../../assets/navbar_platform.svg';
import icon_cart from '../../assets/icon_cart.svg';
import icon_phone from '../../assets/icon_phone.svg';

const NavBarDesktopWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  min-width: ${MAX_WIDTH};
  a span {
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    text-align: center;
  }
  a span:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a span:hover:after {
    width: 100%;
    left: 0;
  }
  .content {
    background: linear-gradient(180deg, rgba(66, 60, 47, 0.5) 0%, rgba(141, 141, 141, 0) 100%);
    .left {
      width: 20%;
    }
    .middle {
      width: 80%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr;
      a img {
        display: none;
      }
    }
    .right {
      width: 20%;
    }
    .navbar_platform {
      display: block;
      position: absolute;
      bottom: -24px;
    }
  }
  .white {
    position: relative;
    background: rgba(255, 255, 255, 0.95);
    a {
      color: #292929 !important;
    }
    a.active {
      color: #a88c69 !important;
    }
    a span:after {
      background: #292929;
    }
    .hotline {
      color: #a88c69 !important;
    }
    .logo_src {
      width: 0px;
      height: 0px;
      padding-left: 156px;
      padding-top: 58px;
      background: url(${logo_golden});
    }
  }
  .navbar_platform {
    opacity: 0;
  }
  .white.has_platform .navbar_platform {
    opacity: 1;
  }
  .white:not(.has_platform) + .navbar_border {
    height: 0.5px;
    background-color: black;
    opacity: 0.25;
  }
  .transparent {
    a {
      color: white !important;
    }
    a.active {
      font-weight: bold;
    }
    .logo_src {
      width: 0px;
      height: 0px;
      padding-left: 156px;
      padding-top: 58px;
      background: url(${logo_white});
    }
    .white_invert_icon {
      filter: brightness(0) invert(1);
    }
  }
  .logo {
    height: 100%;
    span {
      display: none;
    }
    img {
      display: block !important;
    }
  }
  .hotline_icon {
    margin-right: 6px;
  }
`;

const NavBarDesktop = ({ isWhitePlatformShown }) => {
  return (
    <NavBarDesktopWrapper className="">
      <div
        id="layout-navbar"
        className={`content navbar_height white content_center_row ${isWhitePlatformShown ? 'has_platform' : ''}`}>
        <div className="left content_center_row">
          <img className="hotline_icon white_invert_icon" src={icon_phone} alt="hotline_icon" />
          <a href={'tel:0917047147'} className="small hotline no_text_decoration">
            HOTLINE: 0917.047.147
          </a>
        </div>
        <div className="middle content_center">
          {topNavItems?.map((item) => {
            const { name, path, key } = item;
            const logoclass = key === 'HOME' ? 'logo' : '';
            return (
              <Link
                key={key}
                to={path}
                activeClassName="active"
                className={`small no_text_decoration text_uppercase content_center ${logoclass}`}>
                <span> {name}</span>
                <img className="logo_src" src="" alt="" />
              </Link>
            );
          })}
        </div>
        <div className="right content_center">
          <Link to="/cart" className="small cart no_text_decoration">
            <img className="white_invert_icon" src={icon_cart} alt="cart_icon" />
          </Link>
        </div>
        <img className="navbar_platform" src={navbar_platform} alt="" />
      </div>
      <div className="navbar_border" />
    </NavBarDesktopWrapper>
  );
};

const NavBarMobileWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  min-width: ${MAX_WIDTH};
  .content {
    height: 65px;
  }
`;

const NavBarMobile = () => {
  return (
    <NavBarMobileWrapper>
      <div className="content">NAVBAR</div>
    </NavBarMobileWrapper>
  );
};

const NavBar = ({ isWhitePlatformShown }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH})` });
  const location = useLocation();

  console.log(location.pathname);
  return isMobile ? <NavBarMobile /> : <NavBarDesktop isWhitePlatformShown={isWhitePlatformShown} />;
};

NavBarDesktop.propTypes = {
  isWhitePlatformShown: PropTypes.bool,
};

NavBar.propTypes = {
  isWhitePlatformShown: PropTypes.bool,
};

export default NavBar;
