import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { topNavItems } from '../utils/routes';

const SEO = ({ activeKey, title = '', description }) => {
  const navItem = topNavItems.find((item) => item.key === activeKey);
  const docTitle = `${title ? title : navItem?.name} - Bình Khương Thôn`;
  const metaDescription = description ? description : `${docTitle} của Bình Khương Thôn`;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{docTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    </>
  );
};

SEO.propTypes = {
  activeKey: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SEO;
