import React from 'react';
import styled from 'styled-components';
import { FaPhoneAlt, FaMapMarked, FaRegEnvelope, FaGlobeAmericas, FaArrowUp } from 'react-icons/fa';
import * as assets from '../../assets';
import { MAX_SMALL_SCREEN_WIDTH } from '../../utils/constants';
import { footerProducts, footerServices } from '../../utils/routes';

const FooterWrapper = styled.div`
  background-color: #333130;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  p,
  a {
    color: #ffffff;
  }
  .bkt-footer-logo {
    width: 190px;
    height: 60px;
  }
  .footer-left-section-contact-item {
    display: flex;
    p {
      padding-left: 14px;
      margin-block-start: 0.75em;
      margin-block-end: 0.75em;
    }
  }
  .footer-left-section {
    display: flex;
    justify-content: space-between;
    width: 60%;
    .footer-left-section-about {
      width: 45%;
      padding-top: 25px;
    }
    .footer-left-section-contact {
      width: 45%;
      padding-top: 96px;
    }
  }
  .footer-right-section {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    width: 35%;
    .footer-nav-product {
      width: 45%;
    }
    .footer-nav-service {
      width: 45%;
    }
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}) {
    display: block;
    .footer-left-section {
      width: 100%;
      display: block;
      .footer-left-section-about {
        width: 100%;
      }
      .footer-left-section-contact {
        width: 100%;
        padding-top: 0px;
      }
    }
    .footer-right-section {
      width: 100%;
      padding-top: 26px;
    }
  }
`;

const FooterCopyrights = styled.div`
  position: relative;
  display: flex;
  border-top: 0.5px solid #ffffff;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 30px;
  padding-top: 40px;
  color: #ffffff;
  a {
    padding-left: 40px;
    color: #ffffff;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}) {
    flex-direction: column;
    a {
      padding-left: 0px;
      padding-top: 10px;
      color: #ffffff;
    }
  }
  .footer-totop {
    background-color: transparent;
    outline: 0;
    border: 0;
    position: absolute;
    right: 26px;
    bottom: 8px;
    cursor: pointer;
  }
`;

const Footer = () => {
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  return (
    <FooterWrapper>
      <FooterContent>
        <div className="footer-left-section">
          <div className="footer-left-section-about">
            <div className="bkt-footer-logo">
              <img src={assets.white_logo} width={'100%'} height={'100%'} alt="bkt-footer-logo" />
            </div>
            <p className="small color_white">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.
            </p>
          </div>
          <div className="footer-left-section-contact">
            <div className="footer-left-section-contact-item">
              <FaPhoneAlt color="#ffffff" size="16px" />
              <p className="small color_white">1-677-124-44227</p>
            </div>
            <div className="footer-left-section-contact-item">
              <FaMapMarked color="#ffffff" size="16px" />
              <p className="small color_white">184 Main Collins Street Victoria </p>
            </div>
            <div className="footer-left-section-contact-item">
              <FaRegEnvelope color="#ffffff" size="16px" />
              <p className="small color_white">binhkhuongthon@gmail.com</p>
            </div>
            <div className="footer-left-section-contact-item">
              <FaGlobeAmericas color="#ffffff" size="16px" />
              <p className="small color_white">binhkhuongthon.vn</p>
            </div>
          </div>
        </div>
        <div className="footer-right-section">
          <div className="footer-nav-product">
            <p>
              <h6 className="color_white">SẢN PHẨM</h6>
            </p>
            {footerProducts.map((item) => (
              <a key={item.key} href={item.path}>
                <p className="small color_white">{item.name}</p>
              </a>
            ))}
          </div>
          <div className="footer-nav-service">
            <p>
              <h6 className="color_white">DỊCH VỤ</h6>
            </p>
            {footerServices.map((item) => (
              <a key={item.key} href={item.path}>
                <p className="small color_white">{item.name}</p>
              </a>
            ))}
          </div>
        </div>
      </FooterContent>
      <FooterCopyrights>
        <span>© 2020 Boba Team</span>
        <a>Chính Sách — Điều Khoản Sử dụng</a>
        <button className="footer-totop" onClick={scrollToTop}>
          <FaArrowUp color="#ffffff" size="26px" />
        </button>
      </FooterCopyrights>
    </FooterWrapper>
  );
};

Footer.propTypes = {};

export default Footer;
