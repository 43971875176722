import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../utils/constants';

const BannerWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  .banner-background {
    height: 704px;
    width: 100%;
    object-fit: cover;
  }
  .banner-text {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .banner-background {
      height: 400px;
    }
    .banner-text {
      left: 24px;
    }
  }
`;

const Banner = ({ title, description, image }) => {
  return (
    <BannerWrapper id="banner_wrapper">
      <img className="banner-background" src={image} alt="title" />
      <div className="banner-text">
        <h2 className="color_white text_shadow max_width text_center">{title}</h2>
        <h5 className="color_white text_shadow max_width text_center">{description}</h5>
      </div>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.any,
};

export default Banner;
