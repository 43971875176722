import dayjs from 'dayjs';

export const formatDatetime = (datetimestring) => {
  const datetime = dayjs(datetimestring);
  return `${datetime.date()}/${datetime.month() + 1}/${datetime.year()} ${datetime.hour()}:${datetime.minute()}`;
};

export const formatYoutubeLink = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export const flattenStrapiData = (strapiData) => {
  return strapiData?.edges.map(({ node }) => node);
};

export const formatCurrency = (price) => {
  return (
    price.toString().replace(/./g, function (c, i, a) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
    }) + 'đ'
  );
};

export const getBlogRelativePath = (item) => {
  // queried item must contains slug field
  return `/blog/${item?.slug}`;
};

export const getProductRelativePath = (item) => {
  // queried item must contains product_type and slug fields
  return `${item?.product_type?.url}/${item?.slug}`;
};

export const getOriginalImageSrc = (img) => {
  return img?.localFile?.childImageSharp?.original?.src || '';
};

export const getFinalProductPrice = (product) => {
  const discount_price = product?.discount_price;
  return formatCurrency(discount_price ? discount_price : product?.unit_price);
};

export const normalizeItem = (item) => {
  return {
    id: item.id,
    name: item.name,
    unit_price: getFinalProductPrice(item),
    top_image_url: item.product_thumbnail.localFile.childImageSharp.original.src,
    relative_url: getProductRelativePath(item),
  };
};
