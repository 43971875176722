import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ImageWrapper = styled.div`
  cursor: pointer;
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  .linear-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
`;

const Image = ({ width, height, fixedImg, alt, hasGradient = false }) => {
  return (
    <ImageWrapper width={width} height={height} className="image-wrapper">
      <Img objectFit="cover" fixed={fixedImg} style={{ width: '100%', height: '100%' }} alt={alt} />
      {hasGradient ? <div className="linear-gradient" /> : null}
    </ImageWrapper>
  );
};

Image.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fixedImg: PropTypes.any,
  alt: PropTypes.string,
  hasGradient: PropTypes.bool,
};

export default Image;
