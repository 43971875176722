import React from 'react';
import PropTypes from 'prop-types';

const Slider = ({ children }) => {
  return (
    <div uk-slider="">
      <div className=" uk-position-relative">
        <div className="uk-slider-container uk-dark">
          <ul className="uk-slider-items uk-child-width-1-4@s uk-child-width-1-2@ uk-grid">{children}</ul>
        </div>
        <div className="uk-visible@s">
          <div
            className="uk-position-center-left-out uk-position-small uk-hidden-hover slider-arrow"
            uk-slidenav-previous=""
            uk-slider-item="previous"></div>
          <div
            className="uk-position-center-right-out uk-position-small uk-hidden-hover slider-arrow"
            uk-slidenav-next=""
            uk-slider-item="next"></div>
        </div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.any,
};

export default Slider;
