import React from 'react';
import styled from 'styled-components';

const BannerTextWrapper = styled.div`
  width: 100%;
  height: 150px;
  background-color: #E1DFDB;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto;
  }
`;

const BannerText = ({ title }) => {
  return (
    <BannerTextWrapper>
      <div className="max_width">
        <h4>{title}</h4>
      </div>
    </BannerTextWrapper>
  );
};

export default BannerText;
