import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from '../../utils/ui';

// const sampleBreadcrum = [
//   {
//     id: 0,
//     name: 'Home',
//     slug: '/',
//   },
//   {
//     id: 1,
//     name: 'Product',
//     slug: '/product',
//   },
//   {
//     id: 2,
//     name: 'Apple iPhone 12 Pro Max 128GB',
//     slug: '/product/apple-iphone-12-pro-max-128gb',
//   },
// ];

const Breadcrum = ({ breadcrumTree }) => {
  const breadcrumTreeLength = breadcrumTree?.length || 0;
  return (
    <ul className="uk-breadcrumb">
      {breadcrumTree.map((item, index) => {
        if (index === breadcrumTreeLength - 1)
          return (
            <li key={item.id}>
              <span className="text_uppercase color_light_brown">{item?.name}</span>
            </li>
          );
        return (
          <li key={item.id}>
            <StyledLink className="text_uppercase" to={item?.slug}>{item?.name}</StyledLink>
          </li>
        );
      })}
    </ul>
  );
};

Breadcrum.propTypes = {
  breadcrumTree: PropTypes.array,
};

export default Breadcrum;
