import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import icon_cart_empty from '../assets/icon_cart_empty.svg';
import icon_cart_close from '../assets/icon_cart_close.svg';

const CartSidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(114, 117, 126, 0.7);
  z-index: 200;
  display: flex;
  flex-direction: column;
  .sidebar {
    box-sizing: border-box;
    width: 408px;
    height: 100%;
    background-color: white;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
  }
  .sidebar_content {
    overflow: scroll;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 53px;
    text-align: center;
    .close_button {
      position: absolute;
      top: 34px;
      right: 382px;
      cursor: pointer;
    }
  }
  .bottom_section {
    padding: 24px;
  }
  .shopping_button {
    width: 100%;
  }
`;

const CartSidebar = (props) => {
  return (
    <CartSidebarWrapper>
      <div className="sidebar">
        <div className="sidebar_content content_center">
          <div className="close_button">
            <img src={icon_cart_close} alt="icon_cart_close" />
          </div>
          <img src={icon_cart_empty} alt="icon_cart_empty" />
          <h5>Giỏ hàng của bạn đang trống</h5>
          <p>Có vẻ như bạn chưa chọn được sản phẩm nào để thêm vào giỏ hàng của mình. Bắt đầu mua sắm ngay bạn nhé!</p>
        </div>
        <div className="bottom_section">
        <a href="#" className="shopping_button button read_more">mua sắm ngay</a>
        </div>
      </div>
    </CartSidebarWrapper>
  );
};

CartSidebar.propTypes = {};

export default CartSidebar;
