import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BannerSliderWrapper = styled.div`
  .banner-content {
    margin-left: 60px;
  }
  p {
    width: 50%;
  }
`;

const BannerItem = ({ name, description, image_url }) => {
  return (
    <li>
      <div className="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1.2,1">
        <img src={image_url} alt="" uk-cover="" />
      </div>
      <div className="uk-position-cover" uk-slideshow-parallax="opacity: 0,0,0.2; backgroundColor: #000,#000"></div>
      <div className="uk-position-center-left uk-position-medium banner-content">
        <div uk-slideshow-parallax="scale: 1,1,0.8">
          <h2 uk-slideshow-parallax="x: 200,0,0">{name}</h2>
          <p uk-slideshow-parallax="x: 400,0,0;">{description}</p>
        </div>
      </div>
    </li>
  );
};

const BannerSlider = ({ name, description, slider_images }) => {
  return (
    <BannerSliderWrapper>
      <div
        className="uk-position-relative uk-visible-toggle uk-light"
        tabIndex="-1"
        uk-slideshow="animation: slide; min-height: 300; max-height: 600; autoplay: true">
        <ul className="uk-slideshow-items">
          {slider_images?.map((image) => (
            <BannerItem
              key={image.id}
              name={name}
              description={description}
              image_url={`${process.env.STRAPI_URL}${image.url}`}
            />
          ))}
        </ul>
        <a
          className="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous=""
          uk-slideshow-item="previous"></a>
        <a
          className="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next=""
          uk-slideshow-item="next">
          Next
        </a>
      </div>
    </BannerSliderWrapper>
  );
};

BannerItem.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image_url: PropTypes.string,
};
BannerSlider.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  slider_images: PropTypes.array,
};

export default BannerSlider;
