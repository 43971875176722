import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { MAX_WIDTH } from '../../utils/constants';
import { topNavItems } from '../../utils/routes';
import { StyledLink } from '../../utils/ui';
import logo_golden from '../../assets/logo_golden.svg';
import cart from '../../assets/cart_icon.svg';
import navbar_platform from '../../assets/navbar_platform.svg';

const NavBarWrapper = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  z-index: 100;
  background: #ffffff !important;
  li a {
    font-family: Open Sans !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
  .uk-logo {
    padding: 0 48px;
  }
  .uk-navbar {
    position: initial;
  }
  .cart-icon {
    position: absolute;
    right: 60px;
    top: 30%;
  }
  .uk-navbar-left {
    width: 100%;
  }
  .white-platform {
    position: absolute;
    top: 96%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .active a {
    color: #a88c69 !important;
    font-weight: 600;
    letter-spacing: 0.075em;
  }
  .collapsible-navbar {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    background: #ffffff !important;
    transition: 0.5s;
  }
  .hidden {
    transform: translate3d(0, -88px, 0);
    visibility: hidden;
  }
  .hotline {
    position: absolute;
    left: 60px;
    top: 0;
    bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #a88c69;
    align-items: center;
    display: flex;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      color: #A88C69;
    }
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .cart-icon {
      position: absolute;
      right: 20px;
      height: 100%;
    }
    .white-platform {
      display: none;
    }
  }
`;

const NavBar = ({ activeKey }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH})` });

  useEffect(() => {
    const onScrollDown = () => {
      const scrollXOffset = document.documentElement.scrollTop;
      const topNavCollapsibleEl = document.querySelector('.collapsible-navbar');
      scrollXOffset > 80 ? topNavCollapsibleEl.classList.remove('hidden') : topNavCollapsibleEl.classList.add('hidden');
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScrollDown);
    }
  }, []);

  const _renderMobileLayout = () => {
    const logoItem = topNavItems.slice(3, 4);
    const items = [...logoItem, ...topNavItems.slice(0, 3), ...topNavItems.slice(4, 7)];
    return (
      <nav className="uk-navbar" uk-navbar="mode: click; dropbar: true">
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li>
              <a href="#">
                <FaBars size="22px" color="#767575" />
              </a>
              <div className="uk-navbar-dropdown" style={{ width: '100%', paddingTop: 0, paddingBottom: 16 }}>
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  {items.map((item) => (
                    <>
                      <li className={item.key === activeKey ? 'active' : ''}>
                        <a href={item.path}>{item.name}</a>
                      </li>
                      <li className={item.key !== 'ABOUT_US' ? 'uk-nav-divider' : ''}></li>
                    </>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="uk-navbar-center">
          {logoItem.map((item) => (
            <a className="uk-navbar-item uk-logo" href={item.path} key={item.key}>
              <img src={logo_golden} width={156} height={59} alt="Binh Khuong Thon Logo" />
            </a>
          ))}
        </div>
        <StyledLink to={'/cart'} className="cart-icon">
          <img src={cart} width={20} height={20} alt="Gio hang" />
        </StyledLink>
      </nav>
    );
  };
  const _renderDesktopLayout = () => {
    const leftItems = topNavItems.slice(0, 3);
    const logoItem = topNavItems.slice(3, 4);
    const rightItems = topNavItems.slice(4, 7);
    return (
      <nav className="uk-navbar">
        <a href={'tel:0917047147'} className="hotline">
          <p>HOTLINE: 0917.047.147</p>
        </a>

        <div className="uk-navbar-center">
          <div className="uk-navbar-center-left">
            <div>
              <ul className="uk-navbar-nav">
                {leftItems.map((item) => (
                  <li key={item.key} className={item.key === activeKey ? 'active' : ''}>
                    <a href={item.path}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {logoItem.map((item) => (
            <a className="uk-navbar-item uk-logo" href={item.path} key={item.key}>
              <img src={logo_golden} width={156} height={59} alt="Binh Khuong Thon Logo" />
            </a>
          ))}

          <div className="uk-navbar-center-right">
            <div>
              <ul className="uk-navbar-nav">
                {rightItems.map((item) => (
                  <li key={item.key} className={item.key === activeKey ? 'active' : ''}>
                    <a href={item.path}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <StyledLink to={'/cart'} className="cart-icon">
          <img src={cart} width={20} height={20} alt="Gio hang" />
        </StyledLink>
      </nav>
    );
  };
  return (
    <NavBarWrapper>
      {isMobile ? _renderMobileLayout() : _renderDesktopLayout()}
      <div className="white-platform">
        <img src={navbar_platform} alt="" height={105} />
      </div>
      <div className="collapsible-navbar hidden">{isMobile ? _renderMobileLayout() : _renderDesktopLayout()} </div>
    </NavBarWrapper>
  );
};

NavBar.propTypes = {
  activeKey: PropTypes.any,
};

export default NavBar;
