import React from 'react';
import PropTypes from 'prop-types';
import Banner from './Banner';
import { getOriginalImageSrc } from '../../utils/formatter';

const BannerImageText = ({ banner_text }) => {
  const { background, title, description } = banner_text;
  return <Banner title={title} description={description} image={getOriginalImageSrc(background)} height="704px" />;
};

BannerImageText.propTypes = {
  banner_text: PropTypes.shape({
    background: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default BannerImageText;
