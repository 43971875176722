import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavBarS, Footer } from './common';
import SEO from './SEO';
import CartSidebar from './CartSidebar';

const SiteLayoutWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 100%;
`;

const SiteLayout = ({
  children,
  activeKey,
  title,
  description,
  isHomePage,
  navBarStyle,
  isWhitePlatformShown = false,
}) => {
  return (
    <SiteLayoutWrapper className="SiteLayout">
      {/* <CartSidebar /> */}
      <SEO activeKey={activeKey} title={title} description={description} />
      <NavBarS activeKey={activeKey} isWhitePlatformShown={isWhitePlatformShown}></NavBarS>
      <ContentWrapper>{children}</ContentWrapper>
      {isHomePage ? null : <Footer />}
    </SiteLayoutWrapper>
  );
};

SiteLayout.propTypes = {
  children: PropTypes.any,
  activeKey: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  isHomePage: PropTypes.bool,
  navBarStyle: PropTypes.string,
  isWhitePlatformShown: PropTypes.bool,
};

export default SiteLayout;
