import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MAX_WIDTH } from '../../utils/constants';

const ThumbNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  .uk-thumbnav {
    flex-direction: column;
  }
  li {
    cursor: pointer;
  }
  .thumbnail_wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    border: 0.5px solid #292929;
    padding: 8px;
    box-sizing: border-box;
    img {
      height: 100%;
    }
  }
  .bigimage_wrapper {
    width: 438px;
    height: 674px;
    display: flex;
    justify-content: center;
    border: 0.5px solid #292929;
    padding: 24px;
    box-sizing: border-box;
    margin: 0px 47px 0px 15px;
    img {
      height: 100%;
    }
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    flex-direction: column-reverse;
    margin: 0px 25px;
    .uk-thumbnav {
      flex-direction: row;
      margin-top: 16px;
    }
    .thumbnail_wrapper {
      width: inherit;
      margin-top: 16px;
    }
    .bigimage_wrapper {
      width: inherit;
      margin: unset;
    }
  }
`;

const ThumbNav = ({ images }) => {
  const [activeImageSrc, setActiveImageSrc] = useState(images[0].url);
  return (
    <ThumbNavWrapper>
      <ul className="uk-thumbnav" uk-margin>
        {images.map((image) => (
          <li key={image.url} className={activeImageSrc === image.url ? 'uk-active' : ''}>
            <div onClick={() => setActiveImageSrc(image.url)}>
              <div className="thumbnail_wrapper">
                <img src={`${process.env.STRAPI_URL}${image.url}`} alt="" />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div uk-lightbox="">
        <a href={`${process.env.STRAPI_URL}${activeImageSrc}`}>
          <div className="bigimage_wrapper">
            <img src={`${process.env.STRAPI_URL}${activeImageSrc}`} alt="" />
          </div>
        </a>
      </div>
    </ThumbNavWrapper>
  );
};

ThumbNav.propTypes = { images: PropTypes.array };

export default ThumbNav;
