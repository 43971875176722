export const topNavItems = [
  {
    key: 'CA_COP',
    name: 'Rưọu cả cọp',
    path: '/ruou-ca-cop',
  },
  {
    key: 'GALLERY',
    name: 'Bảo tàng',
    path: '/gallery',
  },
  {
    key: 'PRODUCT',
    name: 'Sản phẩm',
    path: '/product',
  },
  {
    key: 'HOME',
    name: 'Trang chủ',
    path: '/',
  },
  {
    key: 'BLOG',
    name: 'Trang blog',
    path: '/blog',
  },
  {
    key: 'CONTACT',
    name: 'Liên hệ',
    path: '/contact',
  },
  {
    key: 'ABOUT_US',
    name: 'Về chúng tôi',
    path: '/about-us',
  },
];

export const footerProducts = [
  {
    key: 'CA_COP',
    name: 'Rượu Cả Cọp',
    path: '/ruou-ca-cop',
  },
  {
    key: 'CA_ME',
    name: 'Cá Mè',
    path: '/ca-me',
  },
  {
    key: 'THIT_HEO',
    name: 'Thịt Heo Organic',
    path: '/thit-heo',
  },
  {
    key: 'CAY_LUU',
    name: 'Cây Lựu Kiểng',
    path: '/cay-luu-kieng',
  },
  {
    key: 'DIEP_DA',
    name: 'Diệp Da',
    path: '/diep-da',
  },
];

export const footerServices = [
  {
    key: 'GALLERY',
    name: 'Bảo Tàng',
    path: '/gallery',
  },
  {
    key: 'CONTACT',
    name: 'Liên Hệ',
    path: '/contact',
  },
];
