import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowDownCircle from '../../assets/arrow-down-circle.svg';
import { getOriginalImageSrc } from '../../utils/formatter';

const BannerKeyVisualWrapper = styled.div`
  position: relative;
  background-image: url(${(props) => props.background});
  height: 100vh;
  width: 100vw;
  display: flex;
  background-size: cover;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    position: absolute;
    animation: bounce 0.5s infinite alternate;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    bottom: 36px;
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
`;

const BannerKeyVisual = ({ banner_text, onScrollDown }) => {
  const { background, title } = banner_text;
  return (
    <BannerKeyVisualWrapper id="banner-key-visual" background={getOriginalImageSrc(background)}>
      <h2 className="color_white text_shadow max_width text_center">{title}</h2>
      <button onClick={() => onScrollDown()}>
        <img src={arrowDownCircle} alt="" />
      </button>
    </BannerKeyVisualWrapper>
  );
};

BannerKeyVisual.propTypes = {
  banner_text: PropTypes.object,
  onScrollDown: PropTypes.func,
};

export default BannerKeyVisual;
